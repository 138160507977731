<template>
  <div class="bg-primary">
    <div class="container container vw-100" style="height: 120vh">
      <div class="row">
        <div class="col p-3 text-center text-white">
          <img :src="require('@/assets/img/logo.png')" width="100px" alt="" srcset="" />
          <p style="margin-bottom: 0%">Federal Ministry of</p>
          <h3 style="margin-bottom: 0%">HEALTH</h3>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <form
          ref="loginForm"
          v-loading="loading"
          class="bg-white col-md-7 p-5 rounded"
          @submit.prevent="handleLogin"
        >
          <div class="row">
            <div class="col text-center mb-2">
              <h4 class="h4 font-weight-bold text-muted">
                Login to the MSDAT Data Management Interface
              </h4>
              <p class="text-muted">Please enter your accurate details and get access to the DMI</p>
            </div>
          </div>
          <el-alert
            v-if="error"
            title="Authentication Error"
            type="error"
            :description="error"
            effect="dark"
            :closable="false"
          >
          </el-alert>
          <div class="px-3">
            <b-form-group
              label="Username/Email"
              label-for="username_email"
              description=""
              class="text-dark font-weight-bold"
            >
              <b-form-input
                id="username_email"
                v-model="loginForm.username_email"
                type="text"
                class="form-control-lg"
                required
                autocomplete
                :state="validateUsernameEmail"
              ></b-form-input>
              <b-form-invalid-feedback :state="validateUsernameEmail">{{
                validateUsernameEmailMsg
              }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Password" label-for="password" class="text-dark font-weight-bold">
              <b-form-input
                id="password"
                v-model="loginForm.password"
                :type="pwdType"
                autocomplete
                class="form-control-lg"
                required
              ></b-form-input>
            </b-form-group>

            <div class="form-check d-flex justify-content-between pb-2">
              <div>
                <input v-model="loginForm.keepLoggedIn" type="checkbox" class="form-check-input" />
                <label class="form-check-label text-dark">Keep me logged in</label>
              </div>
              <div>
                <a href="#" @click.prevent="showPwd"
                  ><small class="form-text font-weight-bold text-info">Show password</small></a
                >
              </div>
            </div>
            <div class="form-group">
              <span class="text-muted">
                Forgot your password?
                <AppLink to="/reset-password">
                  <a href="#" class="font-weight-bold text-info">Click here</a>
                </AppLink>
              </span>
            </div>
            <button type="submit" class="btn btn-primary btn-block btn-lg shadow">Login</button>
            <div class="mt-3 text-center">
              <span class="text-muted"
                >By logging in, you agree to our Privacy Policy and Terms and Conditions</span
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { validEmail } from "../../utils/validate";
import AppLink from "../../components/layout/components/Sidebar/Link.vue";

export default {
  name: "ResetPassword",
  components: {
    AppLink,
  },
  data() {
    return {
      loginForm: {
        username_email: null,
        password: "",
        keepLoggedIn: false,
      },
      loading: false,
      pwdType: "password",
      redirect: undefined,
      otherQuery: {},
      error: null,
    };
  },
  computed: {
    validateUsernameEmail() {
      return this.loginForm.username_email && this.loginForm.username_email.length > 0;
    },
    validateUsernameEmailMsg() {
      if (!this.validateUsernameEmail) return "Please provide your DMI username / registered email";
      return "";
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  methods: {
    showPwd() {
      if (this.pwdType === "password") {
        this.pwdType = "text";
      } else {
        this.pwdType = "password";
      }
    },
    handleLogin() {
      this.error = null;
      this.loading = true;
      this.$progress.start();
      let method = validEmail(this.loginForm.username_email) ? "email" : "username";
      this.$store
        .dispatch("user/login", { ...this.loginForm, method })
        .then(() => {
          this.$router.push(
            { path: this.redirect || "/", query: this.otherQuery },
            () => {},
            (error) => {
              console.log(error);
            }
          );
          this.loading = false;
          this.$progress.done();
        })
        .catch((error) => {
          if (error?.response) {
            if (error.response.status === 400) {
              this.error = error.response.data.detail;
              return;
            }
          }
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.$progress.done();
        });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
